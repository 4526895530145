<template>
  <div>
    <Header></Header>
    <PromoGif></PromoGif>
    <div class="holder">
      <div class="first-header">
        <h1>It’s Shelta Rewards O’clock!</h1>
        <h6>
          Would you like to end this month with brand new cookers, air
          conditioners, and more?
        </h6>
        <p>
          To celebrate our 3rd anniversary, we are giving over
          <span>100</span> lucky Shelta users amazing gifts, worth
          <span>25 million naira.</span>
        </p>
      </div>

      <div class="reward">
        <div class="d-none d-md-block d-lg-block">
          <img
            style="width: 100%;"
            src="../assets/home/sure-hero-desktop.svg"
            alt=""
          />
        </div>
        <div class="d-none d-block d-md-none d-lg-none d-sm-block">
          <img
            style="width: 100%;"
            src="../assets/home/sure-hero-mobile.svg"
            alt=""
          />
        </div>
        <div class="sure-desktop d-none">
          <div class="sure-desktop-text">
            <h2 class="">Users Reward 2023</h2>
            <p>Stand a change of winning lots of rewards</p>
          </div>
          <img src="../assets/home/sure-desktop.svg" alt="sure" />
          <img class="desktop-gift" src="../assets/home/sure-gift.svg" alt="" />
          <div class="desktop-benefits-rewards">
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>1 Landed property</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Inverters for households</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Refrigerators</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Washing machines</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Cookers</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Annual rent for 10 Tenants</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best Tenant reward</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best Landlord reward</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best SAFEer reward</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best SMART Associate reward </span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span
                >And many more prizes to be won (Blenders, Electric Kettle,
                Airfryer, Television Set, Rechargeable fan, Sound bar, Chopper,
                Juice extractor)</span
              >
            </div>
          </div>
        </div>

        <div class="sure-mobile d-none">
          <div class="sure-mobile-text">
            <h2 class="">Users Reward 2023</h2>
            <p>Stand a change of winning lots of rewards</p>
          </div>
          <img
            class="mobile-sure-bg"
            src="../assets/home/sure-mobile.svg"
            alt="sure"
          />
          <div class="mobile-benefits-rewards">
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>1 Landed property</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Inverters for households</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Refrigerators</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Washing machines</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>5 Cookers</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Annual rent for 10 Tenants</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best Tenant reward</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best Landlord reward</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best SAFEer reward</span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span>Best SMART Associate reward </span>
            </div>
            <div class="rewards-holder">
              <img src="../assets/icons/fram-check.svg" alt="check" />
              <span
                >And many more prizes to be won (Blenders, Electric Kettle,
                Airfryer, Television Set, Rechargeable fan, Sound bar, Chopper,
                Juice extractor)</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="users-enjoy">
        <h2>Here are what Shelta users enjoy!</h2>
        <div class="row">
          <div class="col-md-4">
            <div>
              <div class="benefit-header">
                <img src="../assets/icons/keysicon.svg" alt="key" />
                <span>Rent Finance - SWITCH</span>
              </div>
              <div class="enjoy-holder">
                <!-- <img src="../assets/icons/keysicon.svg" alt="key" /> -->
                <span
                  >Enjoy flexibility as Shelta enables you switch your annual
                  payment to start paying rent monthly, quarterly and
                  biannually, while your landlord gets full annual rent paid to
                  them by Shelta. Eligibility requirement applies.</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div>
              <div class="benefit-header">
                <img src="../assets/icons/home-swap-icon.svg" alt="swap" />
                <span>SWAP</span>
              </div>
              <div class="enjoy-holder">
                <!-- <img src="../assets/icons/home-swap-icon.svg" alt="swap" /> -->
                <span
                  >As a Shelta user, you can Swap your apartment for another
                  within and outside the Shelta ecosystem - T&C applies
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div>
              <div class="benefit-header">
                <img src="../assets/icons/legal-icon.svg" alt="legal" />
                <span>Zero Agency Fee and Zero Legal Fees</span>
              </div>
              <div class="enjoy-holder">
                <!-- <img src="../assets/icons/legal-icon.svg" alt="free" /> -->
                <span
                  >Enjoy Zero Agency and Zero Legal fees as Shelta does not
                  require you to pay agency fee or legal/agreement fee when
                  moving into a new apartment managed by Shelta anywhere in
                  Nigeria</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div>
              <div class="benefit-header">
                <img src="../assets/icons/healthicons.svg" alt="caretaker" />
                <span>Gap Financing</span>
              </div>
              <div class="enjoy-holder">
                <!-- <img src="../assets/icons/healthicons.svg" alt="caretaker" /> -->
                <span
                  >Shelta helps you complete your rent when the need arises.
                  This service is made possible through Shelta SAFE</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div>
              <div class="benefit-header">
                <img
                  src="../assets/icons/inspectionicon.svg"
                  alt="inspection"
                />
                <span>You have a Personal Caretaker</span>
              </div>
              <div class="enjoy-holder">
                <!-- <img src="../assets/icons/inspectionicon.svg" alt="inspection" /> -->
                <span
                  >Tenant and landlord advisory service on all property related
                  issues - A personal caretaker service</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div>
              <div class="benefit-header">
                <img
                  src="../assets/icons/search-property.svg"
                  alt="financing"
                />
                <span>Property Financings</span>
              </div>
              <div class="enjoy-holder">
                <!-- <img src="../assets/icons/search-property.svg" alt="financing" /> -->
                <span
                  >We can help finance your property acquisition project
                  including mortgage facilitation.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="be_user d-none">
        <h1>How To Become A shelta user</h1>
        <div class="row">
          <div class="col-md-6 mb-4">
            <div class="become reg">
              <img src="../assets/icons/profile-icon.svg" alt="profile" />
              <p>Register</p>
              <div>
                <h6>Sign up on our platform</h6>
                <button @click="moveTo" class="btn regbtn">Sign up</button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="become subscribe">
              <img src="../assets/icons/yellow-luck-icon.svg" alt="sub" />
              <p>Subscribe</p>
              <div>
                <h6>Subscribe to gain full access to our services.</h6>
                <button class="btn sub" @click.prevent="membership">
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="giveaway">
        <div class="row">
          <div class="col-md-4 mb-4">
            <div class="giveaway-holder">
              <h2>Weekly Giveaways</h2>
              <h6>To qualify for the weekly giveaways</h6>
              <hr class="mb-4" />

              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span>You must be a Shelta user</span>
              </div>
              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span
                  >Follow us on all our social media platforms @myshelta</span
                >
              </div>
              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span
                  >Maintain your chance to win by inviting others to become
                  Shelta users every week.</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <div class="giveaway-holder">
              <h2>Monthly Giveaways</h2>
              <h6>To qualify for the monthly giveaways</h6>
              <hr class="mb-4" />

              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span>You must be a Shelta user</span>
              </div>
              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span
                  >Follow us on all our social media platforms @myshelta</span
                >
              </div>
              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span
                  >Make a video and post on your page tagging @myshelta. 5
                  videos with the highest engagements on the 14th of the month
                  will be posted on our page for further engagement</span
                >
              </div>
              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span
                  >The video with the Highest engagement on our page by the 29th
                  of the month from the selected 5 will win the prize for the
                  month.</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="giveaway-holder">
              <h2>Grand Finale</h2>
              <h6>To qualify for the grand finale</h6>
              <hr class="mb-4" />

              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span>You must be a Shelta user</span>
              </div>
              <div class="check-giveaway">
                <img src="../assets/icons/check.svg" alt="check" />
                <span
                  >Follow us on all our social media platforms @myshelta</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="terms-condition">
        <div class="terms-header">
          <h1>Terms and conditions</h1>
          <div></div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="tc-holder">
              <span class="number">01</span>
              <span class="text">To qualify, you must be a Shelta user.</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="tc-holder">
              <span class="number">02</span>
              <span class="text"
                >User with the highest engagement on social media wins the
                prizes.</span
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="tc-holder">
              <span class="number">03</span>
              <span class="text"
                >You can only participate in the week you register. To
                participate in the following weeks promo, you should invite
                three friends to become Shelta users.</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-bottom" v-if="!showBase">
      <div class="bottom-row">
        <p>
          Please note, old and new Shelta users are eligible for the rewards.
        </p>
        <button
          class="btn"
          @click.prevent="
            membership();
            sure2023();
          "
        >
          Become a Shelta User
        </button>
      </div>
    </div>
    <FooterMain v-if="showBase"></FooterMain>
    <subscriptionModal />
  </div>
</template>

<script>
import Header from "../views/Header";
import FooterMain from "../views/FooterMain";
import PromoGif from "../components/PromoGif.vue";
import subscriptionModal from "../components/SubscriptionModal.vue";
export default {
  name: "Sure2023",
  components: {
    Header,
    FooterMain,
    PromoGif,
    subscriptionModal
  },
  data() {
    return {
      showBase: false
    };
  },

  mounted() {
    if (
      localStorage.getItem("shelta") &&
      JSON.parse(localStorage.getItem("shelta-user")).subscribed == "YES"
    ) {
      this.showBase = true;
    }
  },
  methods: {
    membership() {
      if (localStorage.getItem("shelta")) {
        return this.$root.$refs.D.openSubModal();
      } else {
        this.$router
          .push({
            path: `/signup`
          })
          .catch(() => {});
      }
    },
    moveTo() {
      this.$router
        .push({
          path: `/signup`
        })
        .catch(() => {});
    },
    sure2023() {
      this.$gtag.event("become-a-user", {
        event_category: "become a user click",
        event_label: "Become a user button clicked",
        value: 1
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
$public-sans: url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300&display=swap");

$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$black: #000000;
$shelta-black: #000a2f;
$shelta-gray: #555c74;
$shelta-brown: #553b00;
$shelta-warning: #fff7e6;
$shelta-off-white: #f9fafb;
$shelta-grey-2: #57595a;
$dline: #dddddd;
$off-grey: #f9f9f9;
$disablled-input: #f8fafc;
$e7: #e7e7e7;

.holder {
  background: $white;
  padding: 40px 20px;
}

.reward {
}

.sure-mobile {
  position: relative;
  .mobile-sure-bg {
    width: 100%;
  }
}

.sure-mobile-text {
  position: absolute;
  top: 6%;
  left: 7%;
  h2 {
    color: $white;
    text-shadow: 0px 4px 4px rgba(255, 124, 222, 0.2);
    font-family: $public-sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-align: left;
  }
  p {
    color: $white;
    font-family: $public-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    width: 74%;
  }
}

.mobile-benefits-rewards {
  top: 16%;
  position: absolute;
  left: 68%;
  text-align: left;
  .rewards-holder {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    span {
      color: $shelta-black;
      font-family: Lato;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-left: 6px;
      text-align: left;
    }
  }
}

.sure-desktop-text {
  position: absolute;
  top: 8%;
  left: 11%;
  h2 {
    text-align: center;
    font-family: $public-sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    color: $white;
    margin-bottom: 8px;
  }
  p {
    color: $white;
    text-align: center;
    font-family: $public-sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 79%;
  }
}
.sure-desktop {
  position: relative;
}
.main-bg {
  position: relative;
}
.desktop-gift {
  position: absolute;
  bottom: 65px;
  left: 5%;
}
.desktop-benefits-rewards {
  top: 16%;
  position: absolute;
  left: 68%;
  text-align: left;
  .rewards-holder {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    span {
      color: $shelta-black;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-left: 16px;
      text-align: left;
    }
  }
}

.first-header {
  margin-bottom: 63px;
  h1 {
    color: $shelta-black;
    text-align: center;
    font-family: Lato;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  }
  h6 {
    color: $shelta-black;
    text-align: center;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  p {
    color: $shelta-gray;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    span {
      color: $shelta-black;
      font-weight: bold;
    }
  }
}
@media screen and (max-width: 599px) {
  .holder {
    padding: 40px 15px;
  }
  .first-header {
    h1 {
      text-align: left;
      font-size: 32px;
    }
    h6 {
      text-align: left;
    }
    p {
      text-align: left;
    }
  }
}

.users-enjoy {
  margin-top: 64px;
  h2 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-transform: capitalize;
    text-align: left;
    margin-bottom: 24px;
  }
}

.benefit-header {
  display: flex;
  align-items: flex-start;
  img {
    margin-right: 12px;
  }
  span {
    color: $shelta-black;
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    padding-bottom: 5px;
    // margin-left: 30px;
  }
}
.enjoy-holder {
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 24px;
  margin-left: 35px;
  // img {
  //   padding-right: 12px;
  // }
  span {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

@media screen and (max-width: 599px) {
  .users-enjoy {
    margin-top: 100px;
    h2 {
      font-size: 26px;
      line-height: 150%;
    }
  }
}
.be_user {
  margin-top: 64px;
  margin-bottom: 64px;
  h1 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 24px;
    text-align: left;
  }
}
@media screen and (max-width: 599px) {
  .be_user {
    margin-top: 100px;
    margin-bottom: 100px;
    h1 {
      font-size: 26px;
      line-height: 150%;
    }
  }
}
.become {
  // width: 500px;
  height: auto;
  padding: 24px;
  text-align: left;
  min-height: 211px;
  max-height: 211px;
  p {
    padding-top: 15px;
    color: $shelta-black;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-align: left;
    margin-bottom: 15;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    h6 {
      color: $shelta-gray;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-align: left;
    }
    .regbtn {
      background: $primary;
      color: $white;
    }
    .sub {
      background: $yellow;
      color: $shelta-black;
    }
    button {
      width: 120px;
      height: 32px;
      padding: 0px 8px;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      box-shadow: none;
      outline: 0;
    }
  }
}
.reg {
  background: #eef2fd;
  border-radius: 8px;
}
.subscribe {
  background: #fff7e6;
  border-radius: 8px;
}

.check-giveaway {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  span {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    padding-left: 12px;
  }
}
.giveaway-holder {
  // height: 501px;
  // height: 595px;
  padding: 32px;
  border-radius: 24px;
  border: 4px solid #f9f9f9;
  background: $white;
  h2 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    text-align: left;
    margin-bottom: 16px;
  }
  h6 {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 599px) {
  .giveaway-holder {
    // height: 501px;
    height: auto;
  }
}

.terms-condition {
  padding: 15px;
  margin-top: 64px;
  background: #fafafa;
  border-radius: 8px;
  margin-bottom: 7rem;
}
.terms-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  h1 {
    color: $shelta-black;
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    text-transform: capitalize;
    text-align: left;
    white-space: nowrap;
  }
  div {
    margin-left: 24px;
    height: 1px;
    background: #f2f2f2;
    width: 100%;
  }
}
.tc-holder {
  display: flex;
  align-items: flex-start;
  gap: 0;
  margin-bottom: 20px;
  .number {
    // width: 48px;
    // height: 48px;
    // padding: 12px;
    padding: 10px 12px;
    border-radius: 30px;
    background: $primary;
    color: $white;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
  .text {
    margin-left: 12px;
    color: $shelta-gray;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: left;
  }
}
@media screen and (max-width: 599px) {
  .terms-condition {
    margin-top: 100px;
    margin-bottom: 5rem;
  }
  .terms-header {
    h1 {
      font-size: 26px;
    }
  }
  .tc-holder {
    .number {
      font-size: 15px;
    }
  }
}

.row-bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
}
.bottom-row {
  p {
    color: $shelta-gray;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 0;
  }
  button {
    background: $primary;
    color: $white;
    border-radius: 4px;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    height: auto;
    min-height: 48px;
    box-shadow: none;
    outline: 0;
  }
  padding-left: 200px;
  padding-right: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  background: $white;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.4),
    0px 5px 10px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 599px) {
  .bottom-row {
    padding-top: 16px;
    padding-bottom: 16px;
    // height: 501px;
    height: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
    p {
      display: none;
    }
  }
}

@media screen and (min-width: 600px) {
  //For Tablets
  .container {
    width: 100;
  }
}

@media screen and (min-width: 768px) {
  //For Laptops
  .holder {
    padding: 40px 20px;
  }
  .giveaway-holder {
    min-height: 643px;
    // min-height: 728px;
    //  height: 595px;
  }
  .bottom-row {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 992px) {
  //For Large Laptops
  .holder {
    padding: 40px 200px;
  }
}

@media screen and (min-width: 1280px) {
  //For Big TV's (HD Screens)
  .holder {
    padding: 40px 200px;
  }
}

@media screen and (min-width: 1920px) {
  //For Projectors or Higher Resolution Screens (Full HD)
  .holder {
    padding: 40px 200px;
  }
}
@media screen and (min-width: 3840px) {
  //For 4K Displays (Ultra HD)
  .holder {
    padding: 40px 200px;
  }
}
</style>
